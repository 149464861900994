import { Card, Suit } from "./card";
import { NumberSymbol } from "@angular/common";
import { Player } from "./player";

export class Play
{
    public player: Player;
    public cards: Card[] = [];

    constructor(player: Player, cards: Card[]) {
        this.player = player;
        this.cards = cards;
    }

    get value(): number {
        let value = 0;

        this.cards.forEach(card => {
            value += card.value;
        });

        return value;
    }
    
    get hasClub() {
        return this.cards.findIndex(c => c.suit == Suit.Clubs) > -1;
    }
    
    get hasSpade() {
        return this.cards.findIndex(c => c.suit == Suit.Spades) > -1;
    }
    
    get hasHeart() {
        return this.cards.findIndex(c => c.suit == Suit.Hearts) > -1;
    }
    
    get hasDiamond() {
        return this.cards.findIndex(c => c.suit == Suit.Diamonds) > -1;
    }

    get hasAce() {
        return this.cards.findIndex(c => c.number == 1) > -1;
    }

    get hasJoker() {
        return this.cards.findIndex(c => c.number == 0) > -1;
    }
}