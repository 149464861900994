import { Deck } from "./deck";
import { Player } from "./player";
import { Card, Suit } from "./card";
import { Play } from "./play";
import { GamelogService } from "../app/services/gamelog.service";

export enum GameState {
    "PreGame",
    "Progress",
    "Finished",
};

export enum GamePhase {
    "Play",
    "Discard",
    "ChoosingPlayerForJoker",
}

export enum GameStateResult {
    "Exacties",
    "EnemyDefeated",
    "GameWon",
    "GameLost",
    "Nothing"
}

export class Game
{
    public players: Player[] = [];
    public playerDeck: Deck;
    public enemyDeck: Deck;
    public enemiesDefeated: Card[];
    public playerDiscard: Deck;
    public playerInPlay: Play[] = [];
    public state: GameState = GameState.PreGame;
    public phase: GamePhase = GamePhase.Play;
    public win: boolean = false;
    public numberOfPlayers: number = 2;
    public turnNumber: number = 1;
    public isSolo: boolean = false;

    public soloJesterOne: Card;
    public soloJesterTwo: Card;

    public handLimit: number = 7;
    public username: string = "";
    public isSimulation: boolean = false;
    public gameBroke: boolean = false;

    constructor(
        public gamelogService: GamelogService,
    ) {  }

    private init() {
        this.players = [];
        this.playerDeck = new Deck();
        this.enemyDeck = new Deck();
        this.playerDiscard = new Deck();
        this.playerInPlay = [];
        this.state = GameState.PreGame;
        this.phase = GamePhase.Play;
        this.win = false;
        this.turnNumber = 1;
        this.handLimit = 7;
        this.enemiesDefeated = [];
    }

    startGame(numberPlayers: number = 0, username: string, isSimulation: boolean = false, isSolo: boolean = false) {
        this.isSimulation = isSimulation;

        this.init();

        this.username = username;

        // Shuffle up decks
        this.playerDeck = new Deck();
        this.playerDeck.initPlayerDeck(numberPlayers);

        this.enemyDeck = new Deck();
        this.enemyDeck.initEnemyDeck();

        this.playerDiscard = new Deck();

        this.numberOfPlayers = numberPlayers ? numberPlayers : this.numberOfPlayers;
        this.isSolo = isSolo;

        // Set hand limit
        if (this.numberOfPlayers == 1) {
            this.handLimit = 8;
            this.soloJesterOne = new Card(0);
            this.soloJesterTwo = new Card(0);
            this.soloJesterTwo.isSecondJester = true;
        } else if (this.numberOfPlayers == 3 || isSolo) {
            this.handLimit = 6;
        } else if (this.numberOfPlayers == 4) {
            this.handLimit = 5;
        }

        // Spawn players
        for (let i = 0; i < this.numberOfPlayers; i++) {
            let player = new Player();
            player.username = i == 0 ? this.username : getRandomName();
            player.playerNumber = i;
            this.players[i] = player;
        }

        this.players[0].myturn = true;

        // Draw cards
        this.players.forEach(player => {
            for (let i = 0; i < this.handLimit; i++) {
                this.playerDrawCard(player.playerNumber);
            }
        });

        console.log('In game.ts, about to set to in progress');

        // Set game to "in progress"
        this.state = GameState.Progress;
    }

    // Draws a card for a player, returns false if unable to
    playerDrawCard(playerNumber: number): boolean {
        if (this.players[playerNumber].hand.length >= this.handLimit) {
            return false;
        }

        this.players[playerNumber].hand.push(this.playerDeck.cards.shift());

        return true;
    }

    get currentEnemy(): Card {
        return this.enemyDeck.cards[0];
    }

    get currentPlayerAttack(): number {
        let attack: number = 0;

        this.playerInPlay.forEach(play => {
            attack += this.getPlayAttack(play);
        });

        return attack;
    }

    defeatEnemy(): Card {
        this.enemiesDefeated.push(this.enemyDeck.cards[0]);

        return this.enemyDeck.cards.shift();
    }

    getNextPlayerNumber(playerNumber: number): number {
        let currentPlayerNumber: number = playerNumber;
        let newPlayerNumber: number = 0;
        if (currentPlayerNumber < (this.players.length - 1)) {
            newPlayerNumber = currentPlayerNumber + 1;
        }
        return newPlayerNumber;
    }

    nextTurn(keepSamePlayer: boolean = false) {
        if (!keepSamePlayer) {
            let newPlayerNumber = this.getNextPlayerNumber(this.currentPlayer.playerNumber);
            this.currentPlayer.myturn = false;
            this.players[newPlayerNumber].myturn = true;
        }

        // If all players have no cards, game over!
        let someoneHasCards = false;

        this.players.forEach(player => {
            if (player.hand.length > 0) {
                someoneHasCards = true;
            }
        })

        // Except in 1p game if there's unused Jesters
        if (this.numberOfPlayers > 1 || (this.soloJesterOne.used && this.soloJesterTwo.used)) {
            if (!someoneHasCards) {
                this.lostGame();
                return;
            }
        }

        this.turnNumber++;
    }

    get currentPlayer(): Player {
        return this.players.find(player => player.myturn);
    }

    hitEnemy(play: Play) {
        this.currentEnemy.healthModifier -= this.getPlayAttack(play);
        this.currentEnemy.attackModifier -= this.getPlayDefence(play);
    }

    allPlayersAtHandLimit(): boolean {
        let allPlayersAtHandLimit: boolean = true;

        this.players.forEach(player => {
            if (player.hand.length < this.handLimit) {
                allPlayersAtHandLimit = false;
            }
        });

        return allPlayersAtHandLimit;
    }

    checkGameState(): GameStateResult {
        // Check if enemy is defeated
        let result = GameStateResult.Nothing;

        if (this.currentEnemy.getCurrentHealth() <= 0) {
            if (this.currentEnemy.getCurrentHealth() == 0) {
                // Exacties!
                this.playerDeck.cards.unshift(this.defeatEnemy());
                result = GameStateResult.Exacties;
            } else {
                this.playerDiscard.cards.push(this.defeatEnemy());
                result = GameStateResult.EnemyDefeated;
            }

            if (this.enemyDeck.cards.length == 0) {
                this.wonGame();

                return GameStateResult.GameWon;
            } else {
                // Empty cards in play to discard
                this.playerInPlay.forEach(play => {
                    play.cards.forEach(card => {
                        this.playerDiscard.cards.push(card);
                    });
                });
                this.playerInPlay = [];
            }
        }

        return result;
    }

    getCardName(card: Card) {
        if (card.number > 1) return card.getNumberName() + " of " + card.suit;
        if (card.number == 1) return "Ace of " + card.suit;
        if (card.number == 0) return "The Joker";
    }

    getPlayAttack(play: Play): number {
        return play.hasClub && (this.currentEnemy.suit != Suit.Clubs || this.jokerInPlay())  ? play.value * 2 : play.value;
    }

    getPlayDefence(play: Play): number {
        return play.hasSpade && (this.currentEnemy.suit != Suit.Spades || this.jokerInPlay())  ? play.value : 0;
    }

    getTotalCardsInPlay(): number {
        let total = 0;

        this.playerInPlay.forEach(inPlay => {
            total += inPlay.cards.length;
        });

        return total;
    }

    getTotalCardsInHand(): number {
        let total = 0;

        this.players.forEach(player => {
            total += player.hand.length;
        });

        return total;
    }

    jokerInPlay(): boolean {
        let jokerInPlay: boolean = false

        this.playerInPlay.forEach(play => {
            if (play.hasJoker) {
                jokerInPlay = true;
            }
        });

        return jokerInPlay;
    }

    wonGame() {
        this.win = true;
        this.state = GameState.Finished;

        this.logResult();
    }

    lostGame() {
        this.win = false;
        this.state = GameState.Finished;
        
        this.logResult();
    }

    logResult() {
        let enemies: string[] = [];

        this.enemiesDefeated.forEach(enemy => {
            enemies.push(enemy.getNumberName().substr(0, 1) + enemy.suit.substr(0, 1));
        });
        
        this.enemyDeck.cards.forEach(enemy => {
            enemies.push(enemy.getNumberName().substr(0, 1) + enemy.suit.substr(0, 1));
        });

        // TODO: if this is a simulation, instead just store results in an array and retrieve from app component
        if (this.isSimulation) {
            console.log('logging simulation result');
            this.gamelogService.logSimulationResult(12 - this.enemyDeck.cards.length, enemies.join(","), this.numberOfPlayers, this.handLimit, this.username, this.gameBroke);
        } else {
            this.gamelogService.logResult(12 - this.enemyDeck.cards.length, enemies.join(","), this.numberOfPlayers, this.handLimit, this.username).subscribe(
                result => console.log(result)
            );
        }
    }

    getSoloJoker(jesterNumber: number): Card {
        return jesterNumber == 1 ? this.soloJesterOne : this.soloJesterTwo;
    }
}

function randomEl(list) {
    var i = Math.floor(Math.random() * list.length);
    return list[i];
}

function getRandomName() {
    return randomEl(adjectives)+' '+randomEl(nouns);
}

var adjectives = ["adamant", "adroit", "amatory", "animistic", "antic", "arcadian", "baleful", "bellicose", "bilious", "boorish", "calamitous", "caustic", "cerulean", "comely", "concomitant", "contumacious", "corpulent", "crapulous", "defamatory", "didactic", "dilatory", "dowdy", "efficacious", "effulgent", "egregious", "endemic", "equanimous", "execrable", "fastidious", "feckless", "fecund", "friable", "fulsome", "garrulous", "guileless", "gustatory", "heuristic", "histrionic", "hubristic", "incendiary", "insidious", "insolent", "intransigent", "inveterate", "invidious", "irksome", "jejune", "jocular", "judicious", "lachrymose", "limpid", "loquacious", "luminous", "mannered", "mendacious", "meretricious", "minatory", "mordant", "munificent", "nefarious", "noxious", "obtuse", "parsimonious", "pendulous", "pernicious", "pervasive", "petulant", "platitudinous", "precipitate", "propitious", "puckish", "querulous", "quiescent", "rebarbative", "recalcitant", "redolent", "rhadamanthine", "risible", "ruminative", "sagacious", "salubrious", "sartorial", "sclerotic", "serpentine", "spasmodic", "strident", "taciturn", "tenacious", "tremulous", "trenchant", "turbulent", "turgid", "ubiquitous", "uxorious", "verdant", "voluble", "voracious", "wheedling", "withering", "zealous"];
var nouns = ["ninja", "chair", "pancake", "statue", "unicorn", "rainbows", "laser", "senor", "bunny", "captain", "nibblets", "cupcake", "carrot", "gnomes", "glitter", "potato", "salad", "toejam", "curtains", "beets", "toilet", "exorcism", "stick figures", "mermaid eggs", "sea barnacles", "dragons", "jellybeans", "snakes", "dolls", "bushes", "cookies", "apples", "ice cream", "ukulele", "kazoo", "banjo", "opera singer", "circus", "trampoline", "carousel", "carnival", "locomotive", "hot air balloon", "praying mantis", "animator", "artisan", "artist", "colorist", "inker", "coppersmith", "director", "designer", "flatter", "stylist", "leadman", "limner", "make-up artist", "model", "musician", "penciller", "producer", "scenographer", "set decorator", "silversmith", "teacher", "auto mechanic", "beader", "bobbin boy", "clerk of the chapel", "filling station attendant", "foreman", "maintenance engineering", "mechanic", "miller", "moldmaker", "panel beater", "patternmaker", "plant operator", "plumber", "sawfiler", "shop foreman", "soaper", "stationary engineer", "wheelwright", "woodworkers"];
