import { Card, Suit } from "./card";

export const PLAYER_NUMBERS = [1,2,3,4,5,6,7,8,9,10];
export const ENEMY_NUMBERS = [11,12,13];
//export const SUITS: Suit[] = [Suit.Clubs,Suit.Diamonds,Suit.Hearts,Suit.Spades];

export class Deck
{
    public cards: Card[] = [];
    public playerDeck: boolean = true;

    initPlayerDeck(numberPlayers: number) {
        this.playerDeck = true;

        PLAYER_NUMBERS.forEach(number => {
            Object.keys(Suit).forEach(suit => {
                this.cards.push(new Card(number, suit));
            });
        });

        // Joker (0 for 1p/2p, 1 for 3p, 2 for 4p)
        if (numberPlayers >= 3) {
            this.cards.push(new Card(0));

            // Extra Joker for 4p
            if (numberPlayers == 4) {
                let secondJester = new Card(0);
                secondJester.isSecondJester = true;
                this.cards.push(secondJester);
            }
        }

        this.cards = this.shuffle(this.cards);
    }

    initEnemyDeck() {
        this.playerDeck = false;
        
        ENEMY_NUMBERS.forEach(number => {
            let numberStack: Card[] = [];
            Object.keys(Suit).forEach(suit => {
                numberStack.push(new Card(number, suit));
            });
            numberStack = this.shuffle(numberStack);

            this.cards = this.cards.concat(numberStack);
        });
    }

    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    get lastCard() {
        return this.cards[this.cards.length - 1];
    }
}