import { Injectable } from '@angular/core';
//import * as GameSparks from 'GameSparks';
//import * as CryptoJS from 'CryptoJS';
import { environment } from '../../environments/environment';

declare var GameSparks: any;
declare var CryptoJS: any;

@Injectable({
    providedIn: 'root'
})
export class GamesparksService {

    private _gamesparks;
    public initialised: boolean = false;

    get gamesparks() {
        if (!this.initialised) {
            console.log('initialising GameSparks');

            this._gamesparks = new GameSparks();
    
            this._gamesparks.initPreview({
                key: environment.gs_api_key,
                secret: environment.gs_secret,
                onNonce: this.onNonce,
                onInit: this.onInit,
                onMessage: this.onMessage,
                logger: console.log,
            });

            this.initialised = true;
        }

        return this._gamesparks;
    }

    // Callback function to hmac sha256 a nonce with the secret. It's assumed you will have your own method of securing the secret;
    onNonce(nonce){
        return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(nonce, environment.gs_secret));
    }

    // Callback to handle when the SDK is initialized and ready to go
    onInit(){
        console.log("Initialised");
    }

    // Callback to handle async messages from the gamesparks platform
    onMessage(message){
        console.log(JSON.stringify(message));
    }

    login(username: string, password: string) {
        var request = {};
		request["password"] = password;
        request["userName"] = username;
        
        this.sendRequest("AuthenticationRequest", request);
    }

    sendRequest(requestName: string, params: any) {
        this._gamesparks.sendWithData(requestName, params, this.onResponse);
    }

    onResponse(response){
        console.log(JSON.stringify(response));
    }

}
