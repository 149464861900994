export class SimulationResult
{
    players: number;
    enemiesDefeated: number;
    win: boolean;
    broke: boolean = false;
}

export class SimulationPlayerCountResult
{
    players: number;

    numberGames: number = 0;
    enemiesDefeated: number = 0;
    wins: number = 0;
    losses: number = 0;
    highestEnemiesDefeated: number = 0;
    numberBrokenGames: number = 0;

    constructor(players: number) {
        this.players = players;
    }

    get winPercentage() {
        return ((this.wins / (this.wins + this.losses)) * 100).toFixed(2);
    }

    get averageEnemiesDefeated() {
        return (this.enemiesDefeated / this.numberGames).toFixed(2);
    }
}