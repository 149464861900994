import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { GamesparksService } from './services/gamesparks.service';
import { Game, GameState } from '../models/game';
import { GamelogService } from './services/gamelog.service';
import { CookieService } from 'ngx-cookie-service';
import { OfflineGameComponent } from './offline-game/offline-game.component';
import { SimulationResult, SimulationPlayerCountResult } from '../models/simulation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(OfflineGameComponent)
    private simulationComponent: OfflineGameComponent;

    title = 'app';

    gamesparks: any;
    username: string;
    password: string;

    gameNumberPlayers: number = 4;
    
    simulationResults: SimulationResult[] = [];
    simulationNumberGames: number = 100;
    
    offlineGame: Game = new Game(this.gamelogService);
    simulationGame: Game = null;

    simulationGamesDone: any = {2: 0, 3: 0, 4: 0};
    timer;

    finalResultTwoPlayers: SimulationPlayerCountResult = new SimulationPlayerCountResult(2);
    finalResultThreePlayers: SimulationPlayerCountResult = new SimulationPlayerCountResult(3);
    finalResultFourPlayers: SimulationPlayerCountResult = new SimulationPlayerCountResult(4);
    showFinalResults: boolean = false;

    adminUsernames: string[] = ['Werlix', 'Luke', 'Xenia Wench'];
    rulesLink: string = "https://drive.google.com/file/d/1lXpftXyP7ToNhCW13l5YR5V0o5GBB21u/view?fbclid=IwAR2bnnL-h7WE5ZZJN3rir09DNta-5-iLFIovpOJiGaocl93I-HVHN-Wugmg";

    constructor(
        private gamesparksService: GamesparksService,
        private gamelogService: GamelogService,
        private cookieService: CookieService,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        //this.gamesparks = this.gamesparksService.gamesparks;
        this.username = this.cookieService.get('username');
    }

    inPregame() {
        return this.offlineGame.state == GameState.PreGame;
    }

    login() {
        //this.gamesparksService.login(this.username, this.password);
    }

    startOfflineGame() {
        this.cookieService.set('username', this.username, 14);
        this.offlineGame.startGame(this.gameNumberPlayers, this.username);
    }

    startSoloGame(numberHands: number) {
        this.cookieService.set('username', this.username, 14);
        this.offlineGame.startGame(numberHands, this.username, false, true);
    }

    simulationResult(game: Game) {
        console.log("Got simulation result: " + (game.win ? "WON" : "LOST") + ", " + game.numberOfPlayers + "p");
        this.simulationGame = null;

        this.simulationGamesDone[game.numberOfPlayers]++;
        
        if (this.finishedSimulationGames(2)) {
            if (this.finishedSimulationGames(3)) {
                if (this.finishedSimulationGames(4)) {
                    // Done
                    console.log("Finished all simulations");

                    console.log(GamelogService.simulationResults);

                    this.finishedAllSimulations();
                } else {
                    this.startSimulation(4);
                }
            } else {
                this.startSimulation(3);
            }
        } else {
            this.startSimulation(2);
        }
    }

    finishedSimulationGames(numberOfPlayers: number) {
        console.log('Done ' + this.simulationGamesDone[numberOfPlayers] + ' ' + numberOfPlayers + 'p games');
        return this.simulationGamesDone[numberOfPlayers] >= this.simulationNumberGames;
    }
    
    kickOffSimulation() {
        this.simulationGame = null;
        GamelogService.simulationResults = [];
        this.simulationGamesDone = {2: 0, 3: 0, 4: 0};
        this.finalResultTwoPlayers = new SimulationPlayerCountResult(2);
        this.finalResultThreePlayers = new SimulationPlayerCountResult(3);
        this.finalResultFourPlayers = new SimulationPlayerCountResult(4);
        this.showFinalResults = false;

        this.startSimulation(2);

        let self = this;
        this.timer = setInterval(function() {
            self.carryOnSimulation();
        }, 20000);
    }

    stopTimer() {
        clearInterval(this.timer);
    }

    carryOnSimulation() {
        this.simulationGame = null;
        this.simulationGamesDone = {2: 0, 3: 0, 4: 0};
        this.showFinalResults = false;
        GamelogService.simulationResults = [];

        this.startSimulation(2);
    }

    startSimulation(numberOfPlayers: number) {
        console.log('Starting new ' + numberOfPlayers + 'p simulation');
        this.simulationGame = new Game(this.gamelogService);
        this.simulationGame.startGame(numberOfPlayers, 'AI', true);
        this.cdRef.detectChanges();
        this.simulationComponent.runSimulation();
    }

    finishedAllSimulations() {
        for (let i = 0; i < GamelogService.simulationResults.length; i++) {
            let result = GamelogService.simulationResults[i];

            if (result.players == 2) {
                this.finalResultTwoPlayers.numberGames++;
                this.finalResultTwoPlayers.enemiesDefeated += result.enemiesDefeated;
                if (result.win) {
                    this.finalResultTwoPlayers.wins++;
                } else {
                    this.finalResultTwoPlayers.losses++;
                }
                if (result.enemiesDefeated > this.finalResultTwoPlayers.highestEnemiesDefeated) {
                    this.finalResultTwoPlayers.highestEnemiesDefeated = result.enemiesDefeated;
                }
                if (result.broke) {
                    this.finalResultTwoPlayers.numberBrokenGames++;
                }
            } else if (result.players == 3) {
                this.finalResultThreePlayers.numberGames++;
                this.finalResultThreePlayers.enemiesDefeated += result.enemiesDefeated;
                if (result.win) {
                    this.finalResultThreePlayers.wins++;
                } else {
                    this.finalResultThreePlayers.losses++;
                }
                if (result.enemiesDefeated > this.finalResultThreePlayers.highestEnemiesDefeated) {
                    this.finalResultThreePlayers.highestEnemiesDefeated = result.enemiesDefeated;
                }
                if (result.broke) {
                    this.finalResultThreePlayers.numberBrokenGames++;
                }
            } else if (result.players == 4) {
                this.finalResultFourPlayers.numberGames++;
                this.finalResultFourPlayers.enemiesDefeated += result.enemiesDefeated;
                if (result.win) {
                    this.finalResultFourPlayers.wins++;
                } else {
                    this.finalResultFourPlayers.losses++;
                }
                if (result.enemiesDefeated > this.finalResultFourPlayers.highestEnemiesDefeated) {
                    this.finalResultFourPlayers.highestEnemiesDefeated = result.enemiesDefeated;
                }
                if (result.broke) {
                    this.finalResultFourPlayers.numberBrokenGames++;
                }
            }
        }

        this.showFinalResults = true;
    }

    isAdmin(): boolean {
        return this.adminUsernames.findIndex(u => u == this.username) > -1;
    }
}
