import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game, GameState, GamePhase, GameStateResult } from '../../models/game';
import { Card, Suit } from '../../models/card';
import { Player } from '../../models/player';
import { LogMessage } from '../../models/logMessage';
import { Play } from '../../models/play';
import { GamelogService } from '../services/gamelog.service';
import { debug } from 'util';

const MAX_COMBO = 10;

@Component({
  selector: 'app-offline-game',
  templateUrl: './offline-game.component.html',
  styleUrls: ['./offline-game.component.scss']
})
export class OfflineGameComponent implements OnInit {

    @Input() game: Game;
    @Input() inSimulation: boolean = false;
	@Output() simulationFinished: EventEmitter<Game> = new EventEmitter<Game>();

    log: LogMessage[] = [];
    selectedCards: Card[] = [];

    rulesLink: string = "https://drive.google.com/file/d/1lXpftXyP7ToNhCW13l5YR5V0o5GBB21u/view?fbclid=IwAR2bnnL-h7WE5ZZJN3rir09DNta-5-iLFIovpOJiGaocl93I-HVHN-Wugmg";

    constructor(
        public gamelogService: GamelogService,
    ) { }

    ngOnInit() {
    }

    selectCard(player: Player, card: Card) {
        if (player.hasCardInHand(card) && player.myturn) {
            if (this.isCardSelected(card)) {
                this.selectedCards.splice(this.selectedCards.findIndex(c => c.number == card.number && c.suit == card.suit), 1);
            } else {
                // Are we selecting for attacking?
                if (this.game.phase == GamePhase.Play) {
                    if (this.selectedCards.length == 0) {
                        this.selectedCards.push(card);
                    } else if (this.selectedCards.length >= 1) {
                        if (this.selectedCards.length == 1 && (this.selectedCards[0].number < 2 && card.number > 1 || this.selectedCards[0].number > 1 && card.number < 2)) {
                            // Combine Ace or Joker with card
                            this.selectedCards.push(card);
                        } else {
                            // Combo
                            let comboValid: boolean = true;
                            let totalCombo: number = card.value;

                            this.selectedCards.forEach(selectedCard => {
                                if (selectedCard.number != card.number) {
                                    comboValid = false;
                                }

                                totalCombo += selectedCard.value;

                                if (totalCombo > MAX_COMBO) {
                                    comboValid = false;
                                }
                            });

                            if (comboValid) {
                                this.selectedCards.push(card);
                            }
                        }
                    }
                }
                // Or for discarding?
                if (this.game.phase == GamePhase.Discard) {
                    if (this.getTotalSelectedAmountForDiscard() < this.game.currentEnemy.getCurrentAttack()) {
                        this.selectedCards.push(card);
                    }
                }
            }
        }
    }

    isSolo1Handed(): boolean {
        return this.game.isSolo && this.game.numberOfPlayers == 1;
    }

    playSoloJester(jesterNumber: number) {
        if (!this.game.getSoloJoker(jesterNumber).used) {
            this.playCards(this.game.currentPlayer, new Play(this.game.currentPlayer, [this.game.getSoloJoker(jesterNumber)]));
            this.game.getSoloJoker(jesterNumber).used = true;
        }
    }

    getTotalSelectedAmountForDiscard(): number {
        let amount = 0;

        this.selectedCards.forEach(card => {
            amount += card.value;
        });

        return amount;
    }

    invalidSelection(): boolean {
        if (this.game.phase == GamePhase.Play) {
            if (this.selectedCards.length > 0) {
                return false;
            }
        }

        if (this.game.phase == GamePhase.Discard) {
            if (this.attackRemainingToSelect() <= 0) {
                return false;
            }
        }

        return true;
    }

    discarding(): boolean {
        return this.game.phase == GamePhase.Discard;
    }

    attackRemainingToSelect(): number {
        return Math.max(0, this.game.currentEnemy.getCurrentAttack() - this.getTotalSelectedAmountForDiscard());
    }

    isCardSelected(card: Card): boolean {
        let selectedResult = this.selectedCards.findIndex(c => c.number == card.number && c.suit == card.suit);

        return selectedResult > -1;
    }

    haveWon() {
        return this.game.state == GameState.Finished && this.game.win;
    }

    haveLost() {
        return this.game.state == GameState.Finished && !this.game.win;
    }

    giveup() {
        this.game.lostGame();
    }

    return() {
        this.game.state = GameState.PreGame;
        this.game = new Game(this.gamelogService);
    }

    playCards(player: Player, play: Play) {
        if (player.myturn) {
            // If enemy is immune to the suit played, get confirmation from player first
            if (play.cards.length == 1 && play.cards[0].suit == this.game.currentEnemy.suit && !this.game.jokerInPlay()) {
                if (!confirm("The " + this.game.currentEnemy.getName() + " is immune to " + this.game.currentEnemy.suit + ", are you sure?")) {
                    return;
                }
            }

            let message = 'Playing ';
            let cardsPlayedMessage: string[] = [];
            play.cards.forEach(card => {
                cardsPlayedMessage.push(card.getName());
            });

            this.playerPlay(player.playerNumber, play);

            this.log.unshift(new LogMessage(message + cardsPlayedMessage.join(", "), player));
        } else {
            this.log.unshift(new LogMessage('Tried to cheat', player));
        }
    }

    getPlayerInstruction(player: Player): string {
        if (player.myturn) {
            if (this.game.phase == GamePhase.Play) {
                return "Play card" + (this.selectedCards.length > 1 ? 's' : '');
            }
            if (this.game.phase == GamePhase.Discard) {
                return "Choose cards to discard for damage";
            }
        }

        return "";
    }

    doAction(player: Player) {
        if (player.myturn) {
            if (this.game.phase == GamePhase.Play) {
                // Play card(s)
                if (this.selectedCards.length > 0) {
                    let play: Play = new Play(player, this.selectedCards);

                    this.playCards(player, play);

                    this.selectedCards = [];
                }
            }

            if (this.game.phase == GamePhase.Discard) {
                if (this.attackRemainingToSelect() <= 0) {
                    // Discard cards
                    let cardsDiscarded: Array<string> = [];

                    this.selectedCards.forEach(selectedCard => {
                        let foundIndex = this.game.currentPlayer.getCardIndex(selectedCard);

                        this.game.playerDiscard.cards.push(this.game.currentPlayer.hand[foundIndex]);

                        cardsDiscarded.push(this.game.getCardName(this.game.currentPlayer.hand[foundIndex]));

                        this.game.currentPlayer.hand.splice(foundIndex, 1);
                    });
                    
                    if (cardsDiscarded.length > 0) {
                        this.log.unshift(new LogMessage("Discarded " + cardsDiscarded.join(", "), this.game.currentPlayer));
                    }

                    // Update game phase
                    this.game.phase = GamePhase.Play;

                    // Pass turn
                    this.nextTurn();

                    this.selectedCards = [];
                }
            }
        }
    }

    playerPlay(playerNumber: number, play: Play) {
        // (unless it's the joker is a 1p game)
        if (!play.hasJoker || this.game.numberOfPlayers > 1) {
            // Remove from player's hand
            play.cards.forEach(card => {
                this.game.players[playerNumber].removeCardFromHand(card);
            });

            // Add to "in play"
            this.game.playerInPlay.push(play);

            // Do the card powers
            this.doPlayPowers(play);
        }

        if (play.hasJoker) {
            if (this.game.numberOfPlayers == 1) {
                // If this is a 1p game, discard hand
                let cardsToDraw = this.game.handLimit;
                let cardsDiscarded = this.game.currentPlayer.hand.length;
                this.game.currentPlayer.hand.forEach(card => {
                    let foundIndex = this.game.currentPlayer.getCardIndex(card);

                    this.game.playerDiscard.cards.push(this.game.currentPlayer.hand[foundIndex]);
                });
                this.game.currentPlayer.hand = [];
                // Then draw to full
                let playerToDraw = this.game.currentPlayer;
                let drawHistory: any = {};
                drawHistory[playerToDraw.playerNumber] = 0;
                
                while (cardsToDraw > 0 && playerToDraw.hand.length < this.game.handLimit) {
                    playerToDraw.hand.push(this.game.playerDeck.cards.shift());
                    cardsToDraw--;
                    drawHistory[playerToDraw.playerNumber]++;
                }
    
                for (let pNumber in drawHistory) {
                    this.log.unshift(new LogMessage(this.game.players[pNumber].username + " discarded " + cardsDiscarded + " cards and drew " + drawHistory[pNumber] + " cards"));
                }
            } else {
                this.game.phase = GamePhase.ChoosingPlayerForJoker;
            }
        } else {
            
            this.game.hitEnemy(play);

            // Check game state
            let enemyWas = this.game.currentEnemy;
            let gameStateResult: GameStateResult = this.game.checkGameState();

            switch (gameStateResult) {
                case GameStateResult.GameLost:
                    this.log.unshift(new LogMessage("Game over, man!"));
                break;
                case GameStateResult.GameWon:
                    this.log.unshift(new LogMessage("You win!"));
                break;
                case GameStateResult.EnemyDefeated:
                    this.log.unshift(new LogMessage(enemyWas.getNumberName() + " of " + enemyWas.suit + " was defeated!"));
                    this.nextTurn(true);
                break;
                case GameStateResult.Exacties:
                    this.log.unshift(new LogMessage(enemyWas.getNumberName() + " of " + enemyWas.suit + " was defeated by EXACTIES!"));
                    this.nextTurn(true);
                break;
                default:
                    this.game.phase = GamePhase.Discard;

                    if (this.game.currentPlayer.getMaxAbleToDiscard() < this.game.currentEnemy.getCurrentAttack()) {
                        // Can't discard enough damage - game over, man!
                        // Unless 1p and there's unused jokers
                        if (this.game.numberOfPlayers > 1 || (this.game.soloJesterOne.used && this.game.soloJesterTwo.used)) {
                            this.log.unshift(new LogMessage("Cannot discard enough to cover damage..."));
                            this.game.lostGame();
                        }
                    }
                break;
            }
        }
    }

    nextTurn(keepSamePlayer: boolean = false) {
        this.game.nextTurn(keepSamePlayer);

        // If current player has no cards, pass turn
        if (this.game.state != GameState.Finished && this.game.currentPlayer.hand.length == 0) {
            // Also check this isn't 1p with available Jesters
            if (this.game.numberOfPlayers > 1 || (this.game.soloJesterOne.used && this.game.soloJesterTwo.used)) {
                this.passTurn(this.game.currentPlayer);
            }
        }
    }

    doPlayPowers(play: Play) {
        if (play.hasHeart && (this.game.currentEnemy.suit != Suit.Hearts || this.game.jokerInPlay())) {
            this.game.playerDiscard = this.game.playerDiscard.shuffle(this.game.playerDiscard);
            let cardsHealed: number = 0;
            for (let i = 0; i < play.value; i++) {
                if (this.game.playerDiscard.cards.length > 0) {
                    this.game.playerDeck.cards.push(this.game.playerDiscard.cards.pop());
                    cardsHealed++;
                }
            }
            if (cardsHealed > 0) {
                this.game.playerDeck = this.game.playerDeck.shuffle(this.game.playerDeck);
            }
            this.log.unshift(new LogMessage("Healed " + cardsHealed + " cards"))
        }

        if (play.hasDiamond && (this.game.currentEnemy.suit != Suit.Diamonds || this.game.jokerInPlay())) {
            let cardsToDraw = Math.min(this.game.playerDeck.cards.length, play.value);
            let playerToDraw: Player = play.player;

            let drawHistory: any = {};
            this.game.players.forEach(player => {
                drawHistory[player.playerNumber] = 0;
            });

            let sanity = 40;
            while (cardsToDraw > 0 && !this.game.allPlayersAtHandLimit() && sanity > 0) {
                if (playerToDraw.hand.length < this.game.handLimit) {
                    playerToDraw.hand.push(this.game.playerDeck.cards.shift());
                    cardsToDraw--;
                    drawHistory[playerToDraw.playerNumber]++;
                }

                playerToDraw = this.game.players[this.game.getNextPlayerNumber(playerToDraw.playerNumber)]

                sanity--;
            }

            for (let pNumber in drawHistory) {
                this.log.unshift(new LogMessage(this.game.players[pNumber].username + " drew " + drawHistory[pNumber] + " cards"));
            }
        }

        // Joker
        if (play.hasJoker) {
            console.log('joker found!');
            // Go through previous plays and update attack/defence
            if (this.game.currentEnemy.suit == Suit.Clubs) {
                this.game.playerInPlay.forEach(play => {
                    if (play.hasClub) {
                        this.game.currentEnemy.healthModifier -= play.value;
                    }
                })
            }
            if (this.game.currentEnemy.suit == Suit.Spades) {
                this.game.playerInPlay.forEach(play => {
                    if (play.hasSpade) {
                        this.game.currentEnemy.attackModifier -= play.value
                    }
                })
            }
        }
    }

    startover() {
        this.game.startGame(this.game.numberOfPlayers, this.game.username, this.game.isSimulation, this.game.isSolo);
    }

    passTurn(player: Player) {
        if (player.myturn) {
            this.game.phase = GamePhase.Discard;
            
            if (this.game.currentPlayer.getMaxAbleToDiscard() < this.game.currentEnemy.getCurrentAttack()) {
                // Can't discard enough damage - game over, man!
                // Unless it's a 1p game with Jesters remaining
                if (this.game.numberOfPlayers > 1 || (this.game.soloJesterOne.used && this.game.soloJesterTwo.used)) {
                    this.log.unshift(new LogMessage("Cannot discard enough to cover damage..."));
                    this.game.lostGame();
                }
            }
        }
    }

    choosingPlayerForJoker(): boolean {
        return this.game.phase == GamePhase.ChoosingPlayerForJoker;
    }

    choosePlayerForJoker(player: Player) {
        this.game.currentPlayer.myturn = false;
        player.myturn = true;
        this.game.phase = GamePhase.Play;
    }

    /*startSimulation() {
        this.offlineGameComponent.startSimulation(3);
    }*/

    getHandCardNames() {
        let names = [];

        for (let i in this.game.currentPlayer.hand) {
            names.push(this.game.currentPlayer.hand[i].getName());
        }

        return names.join(", ");
    }

    runSimulation() {

        console.log('Running simulation');

        let numPlays: number = 0;

        while (this.game.state != GameState.Finished) {
            if (this.game.state == GameState.Progress) {

                switch (this.game.phase) {
                    case GamePhase.Play:

                        console.log("SIMULATION | Facing: " + this.game.currentEnemy.getName() + " " + this.game.currentEnemy.getCurrentAttack() + "/" + this.game.currentEnemy.getCurrentHealth());

                        if (numPlays > 300) {
                            // Something went wrong
                            console.log("SIMULATION | GAME BROKE");
                            console.log(this.game);
                            this.game.gameBroke = true;
                            this.game.lostGame();
                        } else {
                            if (numPlays > 100) {
                                debugger;
                            }
                            if (this.game.currentPlayer.hand.length == 0) {
                                console.log("SIMULATION | PASS");
                                this.passTurn(this.game.currentPlayer);
                            } else {
                                console.log("SIMULATION | PLAY");

                                console.log("SIMULATION | Hand = " + this.getHandCardNames());
                                // If we have an Ace, select that also
                                let aceIndex = this.game.currentPlayer.hand.findIndex(card => card.number == 1);
                                if (aceIndex > -1) {
                                    this.selectCard(this.game.currentPlayer, this.game.currentPlayer.hand[aceIndex]);
                                }

                                if (this.selectedCards.length < this.game.currentPlayer.hand.length) {
                                    // Play 1st card in hand that isn't the current boss' suit
                                    let selectedIndex = this.aiChooseCardIndexToPlay(this.game.currentEnemy.getCurrentHealth());

                                    this.selectCard(this.game.currentPlayer, this.game.currentPlayer.hand[selectedIndex]);
                                }

                                let selectedCardNames = [];
                                for (var i in this.selectedCards) {
                                    selectedCardNames.push(this.selectedCards[i].getName());
                                }

                                console.log("SIMULATION | Playing " + selectedCardNames.join(", "));

                                this.doAction(this.game.currentPlayer);

                                numPlays++;
                            }
                        }
                    break;
                    case GamePhase.Discard:
                        console.log("SIMULATION | DISCARD");

                        console.log("SIMULATION | Hand = " + this.getHandCardNames());

                        //console.log('Attempting to discard ' + this.attackRemainingToSelect());
                        //console.log(this.game.currentPlayer.hand);

                        let remaining = this.attackRemainingToSelect();
                        let chosenIndices: number[] = [];

                        //TODO: fix this ungodly mess

                        // Discard least cards possible
                        while (this.attackRemainingToSelect() > 0 && this.selectedCards.length < this.game.currentPlayer.hand.length) {
                            //console.log(this.attackRemainingToSelect() + ' remaining to discard');

                            let indexToDiscard = this.aiChooseCardIndexToDiscard(this.attackRemainingToSelect());

                            console.log('SIMULATION | Discarding card ' + this.game.currentPlayer.hand[indexToDiscard].getName());

                            chosenIndices.push(indexToDiscard);

                            this.selectCard(this.game.currentPlayer, this.game.currentPlayer.hand[indexToDiscard]);
                        }

                        this.doAction(this.game.currentPlayer);
                    break;
                    case GamePhase.ChoosingPlayerForJoker:
                        console.log("SIMULATION | JOKER");
                        // Choose player with the most cards
                        let playerIndex: number = 0;
                        let mostCards: number = 0;
                        let chosenPlayerIndex: number = 0;

                        this.game.players.forEach(player => {
                            if (player.hand.length > mostCards) {
                                chosenPlayerIndex = playerIndex;
                                mostCards = player.hand.length;
                            }
                            playerIndex++;
                        }, this);
                        this.choosePlayerForJoker(this.game.players[chosenPlayerIndex]);
                    break;
                }

                console.log("SIMULATION | Enemies remaining: " + this.game.enemyDeck.cards.length);
            }
        }

        // Game finished
        console.log("SIMULATION || FINISH");
        this.simulationFinished.emit(this.game);
    }

    // TODO: allow passing
    aiChooseCardIndexToPlay(remaining: number): number {
        let weightings = {};

        for (let i in this.game.currentPlayer.hand) {
            let card = this.game.currentPlayer.hand[i];
            
            if (this.isCardSelected(card)) {
                continue;
            }

            // Clone selected cards
            let alreadySelectedCards: Card[] = [...this.selectedCards];

            let hypotheticalPlay = new Play(this.game.currentPlayer, alreadySelectedCards);
            hypotheticalPlay.cards.push(card);

            // Don't play if playing would kill us
            /*let cardsRemaining: Card[] = this.game.currentPlayer.hand.filter(card => !this.isCardSelected(card));
            let valueRemaining = 0;
            for (let j in cardsRemaining) {
                valueRemaining += cardsRemaining[j].
            }*/

            weightings[i] = 0;

            // Assign weighting

            // Matching suit
            if (this.game.currentEnemy.suit == card.suit) {
                weightings[i] -= 100;
            }

            // Kill
            if (this.game.getPlayAttack(hypotheticalPlay) > remaining) {
                weightings[i] += 30;
            }

            // Exacties
            if (this.game.getPlayAttack(hypotheticalPlay) == remaining) {
                weightings[i] += 100;
            }

            switch (card.suit) {
                case Suit.Hearts:
                    weightings[i] += this.game.playerDiscard.cards.length;

                    if (this.game.currentEnemy.getCurrentAttack() == 0) {
                        weightings[i] += this.game.playerDiscard.cards.length;
                    }
                    break;
                case Suit.Diamonds:
                    weightings[i] += (((this.game.handLimit * this.game.numberOfPlayers) - this.game.getTotalCardsInHand()) * 10);
                    break;
                case Suit.Spades:
                    weightings[i] += 15;
                    break;
            }
        }

        // Choose card with highest weighting
        let highestIndex = 0;
        let highestWeighting = -1000;

        for (var i in weightings) {
            if (weightings[i] > highestWeighting) {
                highestIndex = parseInt(i);
                highestWeighting = weightings[i];
            }
        }

        return highestIndex;
    }

    aiChooseCardIndexToDiscard(remaining: number): number {
        let weightings = {};

        for (let i in this.game.currentPlayer.hand) {
            let card = this.game.currentPlayer.hand[i];
            
            if (this.isCardSelected(card)) {
                continue;
            }

            weightings[i] = 0;

            // Assign weighting
            switch (card.suit) {
                case Suit.Clubs:
                    weightings[i] += 20;
                    break;
                case Suit.Hearts:
                    weightings[i] += (30 - this.game.playerDiscard.cards.length);
                    break;
                case Suit.Diamonds:
                    // 10 minus amount of cards missing in hand
                    weightings[i] += 10 - ((this.game.handLimit * this.game.numberOfPlayers) - this.game.getTotalCardsInHand());
                    break;
                case Suit.Spades:
                    weightings[i] += 1;
                    break;
            }

            if (card.value == remaining) {
                weightings[i] += 10;
            } else {
                weightings[i] += ((card.value - remaining) * 5);
            }
        }

        // Choose card with highest weighting
        let highestIndex = 0;
        let highestWeighting = -1000;

        for (var i in weightings) {
            if (weightings[i] > highestWeighting) {
                highestIndex = parseInt(i);
                highestWeighting = weightings[i];
            }
        }

        return highestIndex;
    }
}