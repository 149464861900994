import { Player } from "./player";

export class LogMessage
{
    public player: Player;
    public message: string;

    constructor(message: string, player: Player = null) {
        this.message = message;
        this.player = player;
    }

    get hasPlayer() {
        return this.player instanceof Player;
    }
}