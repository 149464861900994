
export enum Suit {
    Spades = "Spades",
    Clubs = "Clubs",
    Diamonds = "Diamonds",
    Hearts = "Hearts",
}

export class Card
{
    public suit: Suit = Suit.Clubs;
    public number: number = 1;

    public healthModifier: number = 0;
    public attackModifier: number = 0;

    public isSecondJester: boolean = false;
    public isOneOff: boolean = false;
    public used: boolean = false;

    constructor(number, suit = null) {
        this.number = number;
        this.suit = suit;
    }

    get health(): number {
        if (this.number == 13) return 40;
        if (this.number == 12) return 30;
        if (this.number == 11) return 20;

        return 0;
    }

    get attack(): number {
        if (this.number == 13) return 20;
        if (this.number == 12) return 15;
        if (this.number == 11) return 10;

        return this.number;
    }

    get value(): number {
        // Number to use when played or discarded
        return this.attack;
    }

    getCurrentHealth() {
        return this.health + this.healthModifier;
    }

    getCurrentAttack() {
        return Math.max(0, this.attack + this.attackModifier);
    }

    getName(): string {
        if (this.number == 0) return "Jester";

        return this.getNumberName() + " of " + this.suit;
    }
    
    getSuitName():string {
        return this.suit.toString();
    }

    getNumberName(): string {
        if (this.number == 13) {
            return "King";
        }
        if (this.number == 12) {
            return "Queen";
        }
        if (this.number == 11) {
            return "Jack";
        }
        if (this.number == 1) {
            return "Ace";
        }

        return this.number.toString();
    }

    getImage(): string {
        let extension = '.png';

        if (this.number == 0) {
            if (this.isSecondJester) {
                return 'assets/images/cards/Jester2.png';
            } else {
                return 'assets/images/cards/Jester1.png';
            }
        }

        return 'assets/images/cards/' + this.suit + this.getNumberName() + extension;
    }
}