import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimulationResult } from '../../models/simulation';

@Injectable({
    providedIn: 'root'
})
export class GamelogService {

    public enemies: string[] = [];
    public numberOfPlayers: number = 4;
    public handLimit: number = 7;

    static simulationResults: SimulationResult[] = [];

    constructor(
        protected http: HttpClient,
    ) { }

    init(numberOfPlayers: number, handLimit: number) {
        this.enemies = [];
        this.numberOfPlayers = numberOfPlayers;
        this.handLimit = handLimit;
    }

    getData(enemiesDefeated: number, numberOfPlayers: number, handLimit: number, username: string): any {
        let enemiesToDefeat = 12;

        let win: boolean = enemiesDefeated >= enemiesToDefeat;

        return {
            enemies: this.enemies.join(","),
            enemiesDefeated: enemiesDefeated,
            numberOfPlayers: numberOfPlayers,
            handLimit: handLimit,
            win: win ? 1 : 0,
            username: username,
        }
    }

    logResult(enemiesDefeated: number, enemies: string, numberOfPlayers: number, handLimit: number, username: string) {
        let url = 'https://werlixstudios.com/regicide-log/log?';
        let data = this.getData(enemiesDefeated, numberOfPlayers, handLimit, username);

        for (var key in data) {
            url += key + '=' + data[key] + '&';
        }

        url += 'enemies=' + enemies;

        return this.http.get<any>(url);
    }

    logSimulationResult(enemiesDefeated: number, enemies: string, numberOfPlayers: number, handLimit: number, username: string, gameBroke: boolean) {
        let result: SimulationResult = new SimulationResult();
        result.players = numberOfPlayers;
        result.win = enemiesDefeated == 12;
        result.enemiesDefeated = enemiesDefeated;
        result.broke = gameBroke;

        GamelogService.simulationResults.push(result);
    }
}
