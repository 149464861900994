import { Card } from "./card";

export class Player
{
    public username: string;
    public playerNumber: number;
    public hand: Card[] = [];
    public myturn: boolean = false;

    hasCardInHand(card: Card): boolean {
        return this.getCardIndex(card) > -1;
    }

    getCardIndex(card: Card): number {
        return this.hand.findIndex(c => c.number == card.number && c.suit == card.suit);
    }

    removeCardFromHand(card: Card) {
        let result = this.hand.splice(this.hand.findIndex(c => c.number == card.number && c.suit == card.suit), 1);

        if (result.length > 0) {
            return result[0];
        }

        return null;
    }

    getMaxAbleToDiscard() {
        let maxAmount: number = 0;

        this.hand.forEach(card => {
            maxAmount += card.value;
        });

        return maxAmount;
    }
}